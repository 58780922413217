import React from 'react';
import SvgLines from '../Components/SvgLines';
import TopRight from './Illustrations/TopRight';
import BottomRight from './Illustrations/BottomRight';

function Header () {
  return (
    <div id="header" className="app-container">
      <div className="absolute-full-width d-flex flex-column flex-1 align-items-end justify-content-end">
        <div className="fade-in heading heading text-pastel p-4">
          <div>Sept 25th, 2021</div>
        </div>
      </div>

      <div className="absolute-full-width d-flex flex-column flex-1 align-items-center justify-content-center">
        <div className="fade-in main-heading text-pastel">
          <div>Scott &</div>
          <div>Micaela</div>
        </div>
      </div>

      <div className="top-right-container">
        <div className="top-right-illustration">
          <SvgLines stagger={30} className="text-primary" baseClassKey="tr" animate duration={2000}>
            <TopRight />
          </SvgLines>
        </div>
      </div>

      <div className="bottom-left-container">
        <div className="bottom-left-illustration">
          <SvgLines stagger={10} baseClassKey="br" animate duration={2000}>
            <BottomRight />
          </SvgLines>
        </div>
      </div>

      <div className="app-spacer" />
    </div>
  );
}
export default Header;
