import * as React from 'react';

const BottomRight = props => (
  <svg
    id="prefix__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 125.03 130.28"
    xmlSpace="preserve"
    {...props}
  >
    <path
      className="illustration-path"
      d="M19.1 102.16c.38.58 1.59 2.5 1.97 3.08 2 2.97 5.29 4.79 8.41 6.32 3.45 1.69 6.97 3.22 10.6 4.51 2.23.79 4.52 1.41 6.86 1.74 1.67.24 3.37.33 5.06.26 7.13-.29 14.06-3.43 17.85-9.68 1.69-2.78 3.15-7.29.99-10.19-1.77-2.38-5.29-1.94-7.77-3.21-1.24-.64-2.87-1.56-3.72-2.71-3.39-4.58-.51-9.22 4.21-11.15 5.2-2.12 10.25-.54 14.27 3.17 8.73 8.06 7.14 22.26 1.79 31.62-1.7 2.98-3.78 5.55-6.49 7.65-6.96 5.38-14.67 7.38-23.37 6.33-3.68-.44-7.29-1.43-10.72-2.83-3.45-1.4-6.72-3.21-9.8-5.29-3.09-2.09-5.99-4.45-8.71-7-2.52-2.36-4.88-5.05-6.53-8.14-1.77-3.31-2.75-7.01-3.63-10.64"
    />
    <path
      className="illustration-path"
      d="M40.41 116.16c6.89-.45 14.43-3.22 19.45-8.07 1.67-1.61 3.03-3.63 4.28-5.57 1.22-1.88 2.65-4.11 2.84-6.41M59.35 92.26c-1.3 8.54-11.47 14.3-19.28 15.07-3.27.32-6.55-.16-9.76-.76-2.99-.55-6.4-1.82-9.09-3.29M63.07 94.98c-3.46 13.7-21.86 18.95-34.34 16.21"
    />
    <path
      className="illustration-path"
      d="M28.74 52.44C19.45 46.1 7.51 54.49 3.45 63.32c-6.9 14.97.68 30.69 14.58 38.26 3.55 1.93 7.06 3.89 11.04 4.73"
    />
    <path
      className="illustration-path"
      d="M36.17 57.2c.97 2.19 1.19 5.12-.09 7.26-1.16 1.93-3.63 2.9-5.7 3.47-2.35.65-4.5-.2-6.36-1.65-.88-.69-1.46-1.57-2.24-2.32-1.96-1.88-4.53-1.53-6.44.33-5.92 5.8-4.39 15.23-.54 21.53 5.93 9.73 17.47 14.08 17.47 14.08s7.39 3.27 22.52.64"
    />
    <path
      className="illustration-path"
      d="M41.79 66.7c1.64 1.31 2.53 3.22 2.18 5.32-.27 1.6-1.18 3.82-2.79 4.45-1.17.45-2.51.21-3.73.31-2.05.17-4.06 1.29-5 3.17-4.49 8.95 1.89 18.4 1.89 18.4s1.53 2.51 4.85 2.99"
    />
    <path
      className="illustration-path"
      d="M37.8 66.48c-3.99-.22-7.89 3.25-7.89 3.25-3.45 2.69-6.25 6.16-6.61 10.66-.4 4.98 1.56 9.71 3.95 13.97.77 1.37 1.78 2.91 3.08 3.96 0 0 1.24 1.15 1.64 1.44M31.74 43.12c.17.03.35.12.55.26 1.08.77 1.65 1.99 1.8 3.29.16 1.39-.19 2.67-1.19 3.73-.68.72-.27 1.52-.16 2.34.24.02.46.03.69.06 1.42.17 2.52 1.13 2.81 2.46.34 1.54-.2 2.95-1.56 3.71-.58.32-.67.63-.55 1.22.12.58.12 1.18.13 1.78.01.68-.37 1.1-.98 1.36-1.35.57-2.83-.42-2.79-1.86.02-.57.37-1.01.82-1.02.62-.02.97.45.81 1.13-.08.33-.04.59.34.66.39.07.55-.18.53-.5-.03-.57-.1-1.15-.2-1.72-.08-.44-.48-.38-.8-.39-1.69-.06-3.28-1.17-3.91-2.75-.67-1.67-.1-3.62 1.03-4.95.32-.38.69-.73 1.07-1.05.5-.42.48-.83.31-1.37-.23-.69-.5-1.39-.56-2.1-.07-.84-.05-1.7.21-2.51.16-.48.38-.98.72-1.37.3-.35.58-.47.88-.41zm-.45 8.71c-1.61.77-2.5 2.87-1.93 4.37.6 1.59 2.08 2.35 3.02 1.98-.22-1.26-.44-2.52-.67-3.81-.84.23-1.02.9-1.2 1.6-.61-.96-.41-1.89.61-2.64.32-.24.41-.45.3-.81-.07-.22-.09-.45-.13-.69zm.42-7.48c-1.01 1.41-.9 3.99.2 5.13 1.41-1.18 1.32-3.91-.2-5.13zm1.34 9.8c.22 1.27.44 2.53.67 3.81 1.15-.54 1.68-1.58 1.35-2.59-.28-.84-1.18-1.39-2.02-1.22zM55.52 58.49c-.32-1.27-.64-2.54-.97-3.87-1.5.17-2.97.33-4.53.5.04.22.06.38.09.54.29 1.48.59 2.95.88 4.43.21 1.1-.36 2-1.46 2.23-.48.1-1 .13-1.48.07-.63-.07-1.04-.46-1.16-1.12-.11-.65.1-1.17.67-1.46.59-.3 1.23-.5 1.92-.78-.51-2.57-1.03-5.21-1.57-7.93 2.32-.3 4.62-.6 6.98-.91.28 1.16.56 2.28.82 3.4.41 1.79.78 3.58 1.21 5.36.49 2.01-1.06 3.1-2.79 2.88-.77-.1-1.21-.53-1.33-1.23-.12-.71.15-1.26.88-1.57.57-.24 1.18-.35 1.84-.54zm-1.63-6.9c-1.51.2-2.95.39-4.41.58.06.37.14.67.16.97.04.48.29.59.73.53 1.18-.17 2.37-.31 3.55-.47.1-.01.2-.07.33-.12-.12-.51-.24-.99-.36-1.49z"
    />
    <path
      className="illustration-path"
      d="M39.16 55.46c1.98-.16 3.76.24 5.22 1.51.99.86.52 2.65-.78 3.2.14-.28.28-.52.38-.78.34-.87.13-1.45-.69-1.87-.81-.42-1.67-.55-2.63-.49.02.19.02.35.05.51.45 2.49.92 4.97 1.37 7.46.19 1.05-.14 1.82-.94 2.28-.85.49-2.2.44-2.86-.1-.47-.39-.63-.91-.52-1.5.13-.67.62-1.01 1.22-1.17.53-.14 1.09-.2 1.69-.31-.48-2.83-.98-5.72-1.51-8.74zM62.35 64.36c-.25-1.43-.5-2.85-.75-4.27-.25-1.4-.49-2.81-.74-4.26.34-.06.71-.12 1.11-.19.04.13.09.27.11.41.56 3 1.12 5.99 1.67 8.99.17.89.05 1.71-.77 2.25-.87.58-1.81.62-2.75.16-.63-.31-.86-.86-.79-1.53.07-.68.53-1.07 1.14-1.24.58-.15 1.17-.21 1.77-.32zM24.95 50.67c4.94-7.26 8.49-16.72 8.12-24.81C32.59 15.29 25.3 1.37 13.61.2 9.27-.24 4.55 1.28 1.94 4.93c-3.73 5.2-1.68 14.21 5.84 12.22 4.37-1.16 6.63-6.54 11.83-3.99 2.79 1.37 4.27 4.51 5.1 7.35 3.1 10.61-1.43 24.1-9.63 31.23"
    />
    <path
      className="illustration-path"
      d="M19.03 28.3c.36.87.73 1.73 1.07 2.61.17.45.32.93.41 1.41.45 2.59-1.01 5.19-3.48 6.22-.93.39-1.85.77-2.78 1.14-1.61.65-3.21.62-4.79-.11a.662.662 0 00-.46-.01c-.5.18-.99.4-1.48.6-1.45.6-3.1.18-4.11-1.01-.53-.62-.79-1.37-1.05-2.12-.38-1.1.04-2.31 1.02-2.93.5-.31 1.07-.51 1.62-.73.23-.1.3-.19.19-.44-.29-.66-.55-1.33-.83-2-.19-.46-.11-.66.36-.85 4.08-1.68 8.17-3.36 12.25-5.04.47-.19.69-.1.88.36.39.97.79 1.94 1.18 2.9.01 0 0 0 0 0zM5.75 30.72c-.27.11-.34.22-.22.5.7 1.67 1.39 3.36 2.08 5.04.98 2.35 3.75 3.5 6.1 2.54 1-.41 2.01-.83 3.01-1.24 2.03-.84 3.23-3.11 2.77-5.26-.07-.31-.15-.63-.27-.93-.67-1.64-1.35-3.28-2.02-4.93-.12-.29-.24-.34-.53-.22-1.82.76-3.65 1.51-5.47 2.26-1.82.75-3.63 1.5-5.45 2.24zm2.26 7.82a5.565 5.565 0 01-1.34-1.97c-.29-.72-.59-1.42-.87-2.14-.09-.24-.2-.27-.42-.17-.42.19-.86.33-1.26.55-.8.45-1.07 1.1-.79 1.98.24.72.48 1.45 1.09 1.97.76.66 1.64.84 2.6.54.4-.13.79-.32 1.23-.5-.1-.11-.17-.19-.24-.26zM13.51 24.14c.04-.06.18-.21.18-.35 0-.16-.1-.4-.22-.46-.35-.18-.38-.45-.37-.78.01-.38.02-.77-.02-1.15-.05-.52-.41-.82-.86-1.02-.25-.11-.47-.04-.62.2-.13.22-.09.55.15.66.4.19.41.51.38.86-.04.52-.07 1.04.2 1.52.23.41.75.69 1.18.52zM6.7 27.01c.09-.16.23-.3.23-.45 0-.15-.09-.38-.21-.44-.36-.18-.4-.47-.38-.81.03-.49.09-.97-.12-1.44-.16-.37-.46-.58-.82-.72-.25-.1-.52.04-.61.29-.09.26-.03.5.22.61.29.13.36.35.35.63-.01.34-.04.69-.02 1.03.03.59.32 1.03.88 1.25.14.05.29.03.48.05zM9.63 24.52c.08-.13.23-.27.23-.41 0-.16-.09-.4-.22-.47-.33-.17-.38-.43-.36-.75.03-.53.1-1.06-.16-1.56-.18-.35-.47-.55-.83-.66-.23-.07-.44.04-.54.26-.12.25-.07.51.17.62.39.18.39.48.39.82-.01.39-.02.79.04 1.18.07.52.45.8.91.97.09.03.21 0 .37 0z"
    />
    <path
      className="illustration-path"
      d="M11.16 15.37c3.24-1.7 5.98-1.92 8.81.7 2.39 2.21 3.44 5.47 3.73 8.64.49 5.34-.1 10.93-2.77 15.63-1.17 2.07-2.29 4.17-3.96 6.43 0 0-3.22 4.91-6.15 7.52M82.79 116.88c-.07-.27-.14-.55-.22-.82-.11-.38-.11-.37.21-.58 1.09-.71 2.28-1.19 3.5-1.59.78-.25 1.56-.49 2.35-.7.89-.24 1.78-.44 2.68-.64.69-.16 1.38-.28 2.09-.32.34-.02.66.03.99.07.73.1 1.43.05 2.06-.39.31-.22.6-.46.83-.77.3-.39.7-.68 1.12-.92.73-.43 1.47-.85 2.22-1.24.78-.4 1.57-.79 2.36-1.15 1.13-.52 2.28-.99 3.48-1.31.6-.16 1.23-.24 1.84-.36.08-.01.12.01.16.09.16.36.35.72.5 1.09.13.34.08.69-.08 1.01-.2.39-.27.79-.21 1.23.11.86.17 1.72.14 2.58-.01.38-.02.77-.07 1.15-.11.79-.52 1.42-1.12 1.92-.59.49-1.22.94-1.93 1.25-.42.19-.87.34-1.31.47-.58.17-1.18.27-1.79.23-.99-.06-1.88-.41-2.68-1-.81-.61-1.57-1.27-2.22-2.06-.09-.11-.2-.2-.31-.29-.52-.41-1.44-.13-1.57.59-.03.16-.04.33-.04.5-.01 1.18-.15 2.34-.53 3.47-.39 1.18-1.15 2.05-2.24 2.67-1.3.74-2.71 1.04-4.19 1.03-.84-.01-1.59-.31-2.19-.92-.83-.85-1.55-1.78-2.2-2.77-.17-.27-.39-.49-.68-.62-.36-.17-.69-.37-.91-.72.01-.06-.02-.12-.04-.18zm20.76-8.38c-.18.08-.53.21-.87.37-.97.44-1.88.99-2.77 1.58-.5.33-.96.7-1.27 1.23-.35.58-.36 1.16 0 1.72.35.54.71 1.07 1.1 1.58.68.89 1.61 1.35 2.72 1.45.85.07 1.68-.04 2.48-.32.76-.26 1.42-.69 2.07-1.16.89-.64 1.33-1.51 1.28-2.62-.03-.85-.11-1.7-.34-2.53-.1-.35-.21-.71-.39-1.02-.42-.74-1.12-.94-1.91-.86-.67.06-1.3.3-2.1.58zm-15.99 5.83c-.55.26-1.09.51-1.63.77-.36.18-.65.46-.86.81-.35.56-.31 1.14-.07 1.74.38.97.95 1.82 1.58 2.64.48.62 1.09 1.02 1.88 1.14.42.06.84.02 1.26-.02 1.32-.13 2.52-.55 3.55-1.41.93-.77 1.51-1.73 1.54-2.96.02-.75-.06-1.5-.19-2.24-.11-.59-.44-1.01-1.02-1.22-.34-.13-.7-.22-1.06-.19-.54.04-1.08.09-1.62.16-1.14.16-2.26.41-3.36.78zM119.91 101.32s-8.87-21.14-36.56-7.39"
    />
    <path
      className="illustration-path"
      d="M83.35 105.99c4.62-2.89 9.99-4.83 15.12-5.03 4.4-.17 9.31 1.1 12.7 4.06 2.84 2.47 6.03 8.9.86 10.65-.06.01-.12.01-.17.02-1.03.16-2.05.51-2.98.98-.73.37-1.41.82-2.01 1.37-.33.3-.64.64-.92.99 0 0-.97 1.19-.89 3.47.08 2.29 2.07 4.04 3.98 5.12 3.89 2.2 8.53 1.34 11.89-1.45 1.61-1.34 2.52-3.24 3.08-5.23.73-2.6 1.16-5.4.75-8.09-.41-2.67-1.44-5.21-2.58-7.64-7.08-15.26-25.34-17.07-38.32-8.36"
    />
    <path
      className="illustration-path"
      d="M48.46 90.18l1.59 3.32-1.84.88c-.31-.65-1.09-.92-1.73-.61-.65.31-.92 1.09-.61 1.74l-1.84.88-.27-.57M40.58 89.19l-1.98-4.13 1.85-.89c.01.04.02.08.04.12.31.65 1.09.92 1.74.61.65-.31.92-1.09.61-1.73-.02-.04-.04-.07-.06-.11l1.85-.89.66 1.38M46.32 82.33l1.58 1.32c-.03.03-.06.05-.09.09a1.3 1.3 0 001.99 1.67c.03-.03.05-.07.07-.1l1.58 1.32-2.98 3.57-3.17-6.63 1.02-1.24zM43.75 95.82l-.36.43-1.57-1.31a1.3 1.3 0 00-1.99-1.67l-1.57-1.31 2.31-2.77 3.18 6.63z"
    />
  </svg>);

export default BottomRight;
